import React from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import SliderOne from "@/components/slider-one";
import FeatureOne from "@/components/feature-one";

import ServiceOne from "@/components/service-one";
import CtaOne from "@/components/cta-one";

import GalleryOne from "@/components/gallery-one";
import TestimonialsOne from "@/components/testimonials-one";
import SponsorOne from "@/components/sponsor-one";

import ProductList from "@/components/ProductsList";
const HomeOne = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Home Page">
        <HeaderOne />
        <SliderOne />
        <ProductList />
        <FeatureOne />
        {/* <AboutOne /> */}
        <ServiceOne />
        <GalleryOne />

        {/* <FunfactOne /> */}
        <TestimonialsOne />
        <CtaOne />
        <SponsorOne />
        {/* <CtaTwo /> */}
        {/* <BlogOne /> */}
        {/* <ContactOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default HomeOne;
